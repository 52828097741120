import React, {useState, useEffect} from "react";
import logo from '../media/images/SD25/sjateau-box.png';
import {Box, Grommet} from "grommet";
import {ReviveMendixV2} from "../mendix_api/UnauthAPI";
import {SD24} from "../common/Theme_SD24"
import { sleep } from "../common/Utils";
import './SD25-Gate.css';
import GradientCanvas from "./GradientCanvas";
import { SD25Loading } from "./SD25-Loading";
import { SD25Access } from "./SD25-Access";
import {SD25Webshop} from "./SD25-Webshop";

export const TicketPortal = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    
    const [mendixAwake, setMendixAwake] = useState(false);

    const [accessContainer, setAccesContainer] = React.useState({
        animate: true,
        show: false
    });
    const [webShop, setWebshop] = React.useState({
        animate: true,
        show: false
    });
    const [accessCode, setAccessCode] = useState("");
    const [guest, setGuest] = React.useState({
        name:"",
        known:"unkown"
    });

    useEffect(() => {
        const hideAccessContainer = async () => {
            await sleep(2000);
            setAccesContainer(current => ({
                ...current,
                animate: false
            }));
            await sleep(3000);
            setAccesContainer(current => ({
                ...current,
                show: false
            }));
        }

        const showWebshop = async () => {
            await sleep(3000);
            setWebshop(current => ({
                ...current,
                show: true
            }));
        }

        if (guest.known === true){
            hideAccessContainer();
            showWebshop();
        }


    }, [guest]);

    useEffect(() => {
        const awakeMendix = async () => {
            await sleep(3000);
            console.log("Attempt to wake");
            try {
                const response = await ReviveMendixV2();
                if (response.status === 200) {
                    console.log("Mendix has awakened!!!");
                    setMendixAwake(true);
                } else {
                    console.log("Result: " + response.status);
                    awakeMendix();
                }
            } catch (e) {
                console.log(e);
                awakeMendix();
            }
        };

        if (!mendixAwake) {
            console.log("Mendix not awake");
            awakeMendix();
        } else {
            showAccessContainer();
        }

        async function showAccessContainer() {
            await sleep(3000);
            setIsLoading(false);
            setAccesContainer(current => ({
                ...current,
                show: true
            }));
        }

    }, [mendixAwake]);

return (
    <Grommet 
    className="grommet-box"
    theme={SD24}
    style={{}}>
    <Box id="SD25-Gate">
        <img
                        
            fit='contain'
            src={logo}
            style={{paddingTop:"10px", width: "500px", maxWidth: "100vw", zIndex:"2"}}
            alt="logo"
        />
        {isLoading && <SD25Loading isLoading={!mendixAwake} />}
        {accessContainer.show === true && <SD25Access 
            showAccessContainer={accessContainer.animate}
            invitationCode={accessCode} 
            setInvitationCode={setAccessCode}
            invitedGuest={guest}
            setInvitedGuest={setGuest}/>}
        {webShop.show === true && <SD25Webshop
            show={webShop.animate}
            />}
    </Box>
    <GradientCanvas 
        height={ webShop.show ? "200%" : "100%"}
     />
    </Grommet>
    
);
}
export default TicketPortal;