import { deepMerge } from "grommet/utils";
import { grommet } from 'grommet/themes';

export const SD24 = deepMerge(grommet,{
    global: {
        colors: {
            brand: "white",
            secondary: "#EABC48",
            green: '#9ECC84',
            blue: '#42506e',
            pink: '#fb00be',
            darkgreen: '#839b76',
            active: 'secondary',
            "grey-1": "#ECE9E3",
            "grey-2": "#CECCC6",
            "grey-3": "#737069",
            "grey-4": "#52504C",
            /* End color definition */
            background: {
                dark: "secondary",
                light: "brand"
            },
            text: "brand",
            border:"white"

        },
        font: {
            family: 'Rouge Script',
            size:"36px",
            height: "40px"
        },
        focus:{
            border:{
                color: "secondary"
            }
        },
    },
    text:{
        custom: {
            size: '26px',
            height: '45px'
        },
    },

    button:{
        default: {
            color: "brand",
            border: {
                radius: undefined,
                color: 'secondary',
                width: '2px'
            },
            hover: {color: "secondary"},
            font:{
                size:'large',
                weight:'bold'
            }
        },
        hover:{
            default:{
                background:{color:'secondary'}
            }
        }
    }

})