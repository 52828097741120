import React, { useEffect } from "react";
import { Gradient } from "whatamesh";

function GradientCanvas(props) {
    useEffect(() => {
        // Initialize the gradient after the component mounts
        const gradient = new Gradient();
        gradient.initGradient("#gradient-canvas");
    }, []);

    return (
        <canvas
            id="gradient-canvas"
            style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: props.height,
            zIndex: 0,
            "--gradient-color-1": "#a960ee",
            "--gradient-color-2": "#ff333d",
            "--gradient-color-3": "#90e0ff",
            "--gradient-color-4": "#ffcb57;",
            }}
        />
    );
}

export default GradientCanvas;
