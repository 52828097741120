import maarten from '../media/images/SD25/maarten-duitser.png';
import praatwolk from '../media/images/SD25/praatwold.png';
import './SD25-Loading.css';
import Loader from './SD25-Loader.svg';

export const SD25Loading = (props) => {

    return(

    <div id="maarten-loading-container" className={`loader ${props.isLoading ? 'fade-in' : 'fade-out'}`}>
        <div id='text-container'>
            <h1 id='greeting'>
                &emsp; &emsp; &emsp; &emsp; Bonjour, 
            </h1>
            <h1 id='loading-message'>
                Wat leuk dat je naar Sjateau Disceau wilt komen. Ik pak even de gastenlijst erbij...
            </h1>
            <img src={Loader} alt="loader" id='sd25-loader'/>
        </div>
        <img 
            id='sd25-praatwolk'
            alt='praatwolk'
            fit='contain'
            src={praatwolk}
        />
        <img
                id='maarten-loading'
                fit='cover'
                alt='maarten-loading'
                src={maarten}
        />
    </div>

    );
}
export default SD25Loading;