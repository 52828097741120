
export const EASYBASE_PASSWORD = 'Welkom01!'

export const TOTALAMOUNTTICKETS = 90

export const POSTCODE_API_KEY = 'fd571634-b8a7-4da6-9ea4-faa183e420ef'

// const Mendix_Local = 'http://localhost:8080'
const Mendix_Live = 'https://beatsinthewoods-sandbox.mxapps.io'
export const UNAUTHENTICATED_API = Mendix_Live + '/rest/prsunauth/v1/'
export const AUTHENTICATED_API = Mendix_Live + '/rest/prsauthenticated/v1/'



const Live_RECAPTCHA_KEY = '6LeeWaEeAAAAAHOC4qey-Fscxype0f2WS4OJopSI'
//const Local_RECAPTCHA_KEY =  '6LeFd6EeAAAAAPyZOcLUncCKp8-wYXho3tccvT8G'
export const RECAPTCHA_KEY = Live_RECAPTCHA_KEY

//EventIDs
export const SD24_id = 'GtKDZK9DyO'