import React from 'react';
import maarten from '../media/images/SD25/maarten-hey-you-there.png';
import praatwolk from '../media/images/SD25/praatwold.png';
import './SD25-Access.css';
import Loader from './SD25-Loader.svg';  // Ensure you are using this Loader if needed
import VerificationInput from "react-verification-input";
import {checkInvite} from "../mendix_api/UnauthAPI"
import { sleep } from "../common/Utils";

export const SD25Access = (props) => {
    // Use props.invitationCode if you need to manage initial state from an external component
    const [fetching, setFetching] = React.useState(false);

    const checkGuestList = async(v) => {
        const eventId = 'jmomLhJJ2j'
        setFetching(true);
        await sleep(3000);
        console.log("Fetch:" + v)
        let response = await checkInvite(v, eventId);
        let data = await response.json();
        props.setInvitedGuest(data);   
        setFetching(false);
    }

    return (
        <div id="access-container" className={`access-container ${props.showAccessContainer ? 'slide-in' : 'slide-out'}`}>
            <div id='access-text-container'>
                <h1 id='access-greeting'>
                    &emsp; &emsp; &emsp; &emsp; Mon ami, 
                </h1>
                <h1 id='access-message'>
                    {props.invitedGuest.known === true && fetching === false && "Magnifique, jij staat erop. Hier komt de webshop."}
                    {props.invitedGuest.known === false && fetching === false && "Non de jus, deze toegangscode is bij ons helaas onbekend."}
                    {fetching && "Eens even de lijst checken" }
                    {props.invitedGuest.known === "unkown" && fetching===false && "Voer snel je toegangscode in om de webshop te openen."}
                    
                </h1>
                {fetching && <img src={Loader} alt="loader" id='sd25-access-loader'/>}
                <div id='access-code'>
                    <VerificationInput 
                    id='vi'
                    placeholder='?'
                    value={props.invitationCode} 
                    onChange={(v) => {
                        const upperV = v.toUpperCase();
                        console.log("Complete: "+upperV)
                        props.setInvitationCode(upperV);
                    }}
                    onComplete={(v) => {
                        const upperV = v.toUpperCase();
                        console.log("Complete: "+upperV)
                        props.setInvitationCode(upperV);
                        checkGuestList(upperV);
                    }}
                    />
                </div>
            </div>
            <img 
                id='sd25--access-praatwolk'
                alt='praatwolk'
                fit='contain'
                src={praatwolk}
            />
            <img
                id='maarten-access'
                fit='cover'
                alt='maarten-access'
                src={maarten}
            />
        </div>
    );
}

export default SD25Access;
