import React, {useEffect } from "react";
import './SD25-Webshop.scss';
import sjateau from '../media/images/SD25/het-sjateau.png';
import maartenEnTim from '../media/images/SD25/tim-en-maarten.png'
import praatwolk from '../media/images/SD25/praatwold.png';

export const SD25Webshop = (props) => {

    useEffect(() =>{
            if (document.body.getElementsByClassName("ot-container").length === 0){
                console.log("run shop scripts");
                const eventixScript = document.createElement('script');
                eventixScript.src = "https://shop.eventix.io/build/integrate.js"
                eventixScript.async = false;
                document.body.appendChild(eventixScript);
            }
            else {
                console.log("Skip shop scripts")
            }
    });
        

    return (
        <div id="sd25-webshop--container" className={`access-container ${props.show ? 'slide-in' : 'slide-out'}`}>
            <img
                id='sd25-webshop--het-sjateau'
                fit='cover'
                alt='het-sjateau'
                src={sjateau}
            />
            <div id="shop-frame" className="eventix-shop"
                data-url="https://shop.eventix.io/9b02b92b-ad66-4daa-bad2-40d0c20ab950"
                style={{maxWidth: "600px"}}>
            </div>

            <div id='sd25-webshop--code-reminder'>
                <div id='sd25-webshop--code-reminder--praatwolk' >
                    <h1>
                        Hier je unieke code!
                    </h1>
                </div>
                <img
                    id='sd25-webshop--code-reminder--tim-en-maarten'
                    fit='contain'
                    alt='het-sjateau'
                    src={maartenEnTim}
                />   
            </div>
            
            
               

        </div>
    )
}
export default SD25Webshop;